.badge {
  font-size: 14px;
  font-family: var(--regular);
  color: #FFF;
  line-height: 1.2;
  text-align: center;
  padding: 6px 15px;
  background-color: $green;
  display: inline-flex;
  align-items: center;
  border-radius: 10rem;
  min-height: 30px;

  &.badge--small {
    font-size: 10px;
    font-family: var(--medium);
    min-height: 22px;
    line-height: 1.2;
    padding: 4px 10px;
    text-transform: uppercase;
    margin: 0;
  }

  &--blue {
    background-color: $logo-blue;
  }

  &--red {
    background-color: $red;
  }

  &--green {
    background-color: #5ed29f;
  }
}

a.badge:hover {
  color: $white;
}
