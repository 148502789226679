.fading-banner {
  width: 100%;
  background-color: $dark-bg-blue;
  position: fixed;
  bottom: -259px;
  transition:  bottom, 1s;
  z-index: 100;

  & .l-container {
    padding: 25px 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media #{$md} {
      display: block;
    }
  }

  & .btn-primary {
    margin-right: 10px;
  }

  & .fading-banner-btn {
    color: $white;
    background-color: transparent;
    margin-right: 0;
  }

  &.fading-banner-promote {
    bottom: 0;
  }

  & .fading-banner-headlines {
    float: none;
    flex-grow: 2;
  }

  & .fading-banner-ctas {
    float: none;
    flex-shrink: 0;
  }

  & .fading-banner-headline {
    font-family: var(--regular);
    color: #fff;
  }

  & .fading-banner-headline {
    font-size: 24px;
    line-height: 28px;
  }

  & .close {
    margin-right: -7px;
    margin-top: -7px;
    right: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    cursor: pointer;
    z-index: 1001;
    padding: 25px;
    background: transparent url("~images/application/crosssign_small_white.webp") no-repeat scroll center center;
  }

  @media #{$md} {
    & .close {
      display: block;
    }

    & .fading-banner-headlines {
      margin: 0 15px;
      float: none;
      text-align: center;
    }

    & .fading-banner-ctas {
      text-align: center;
      float: none;
    }

    & .btn-primary {
      float: none;
      display: inline-flex;
      margin-top: 10px;
      margin-right: 0;
    }

  }

  @media #{$xs} {
    & .l-container {
      padding: 15px 0 15px
    }

    & .fading-banner-headlines {
      text-align: center;
    }

    & .fading-banner-headline {
      font-size: 13px;
      line-height: 21px;
    }

    & .btn-primary, .fading-banner-ctas {
      float: none;
    }

    & .btn-primary {
      display: flex;
      margin: 13px 15px 0;
      line-height: 32px;
      height: 32px;
      font-size: 10px;
      padding: 0 12px;
    }

  }

}

#language_selection_cta {
  bottom: 0;

  & .l-container {
    justify-content: center;
  }

  & .fading-banner-headlines {
    margin-right: 20px;
    flex-grow: unset;
  }

  & .fading-banner-headline {
    font-size: $standard-font-size;

    @media #{$xs} {
      font-size: 13px;
    }
  }
}
