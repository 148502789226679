.embedded-quiz {
  border: 1px solid $gray-border;
  border-radius: 2px;
  // basic minimal height for lazy loaded container
  min-height: 150px;
  font-family: var(--regular);
  color: $gray-text;

  &.preview {
    border-color: $red;

    & > .fa {
      margin-right: 2px;
    }
  }

  & .preview-banner {
    background-color: $red;
    color: $white;
    text-align: center;
    padding: 12px 15px;
    font-size: 12px;
    line-height: 1.2;

    & .title {
      font-family: var(--regular);
      margin-bottom: 2px;
    }

    & .content {
      font-family: var(--light);

      & .fa {
        margin-left: 4px;
      }
    }

    & a {
      font-size: inherit;
      font-family: var(--medium);
      color: $white;
      text-decoration: underline;
    }

    & .preview-tag {
      display: none;

      & .fa {
        margin-right: 2px;
        position: relative;
        top: -1px;
      }

      @media #{$xs} {
        display: inline-flex;
        padding: 0;
        min-height: 0;
        margin: 0 0 8px;
        font-size: 12px;
      }
    }
  }

  & p.tiny {
    color: $gray-text;
    font-family: var(--regular);
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  & > .embedded-quiz__header {
    border-bottom: 1px solid $gray-border;
    height: 101px;
    display: flex;

    @media #{$xs} {
      height: 81px;
    }

    & .preview-tag {
      position: absolute;
      top: -11px;
      right: 38px;

      @media #{$xs} {
        display: none;
      }
    }

    & > .embedded-quiz__image {
      float: left;
      height: 100px;
      width: 100px;
      background-color: #ffffff;
      position: relative;

      @media #{$xs} {
        height: 80px;
        width: 80px;
      }

      & > img {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        position: absolute;
      }
    }

    & > .embedded-quiz__info {
      flex: 1;
      border-left: 1px solid #ececec;
      padding: 0 30px;
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      @media #{$xs} {
        padding: 0;
        height: 80px;
        width: calc(100% - 80px);
      }

      & p {
        margin-right: 20px;

        @media #{$sm} {
          display: none;
        }

        & a {
          text-decoration: underline;
          color: $gray-text;
          white-space: nowrap;
        }
      }

      & .btn.btn-primary {
        margin-right: 0;
        white-space: nowrap;
        flex-direction: column;

        @media #{$xs} {
          width: 100%;
          height: 81px;
          border-radius: 0;
        }
      }
    }
  }

  & > .embedded-quiz__main {
    background-color: $gray-bg;
    position: relative;

    .close {
      top: 10px;
      right: 20px;
      position: absolute;
      width: 19px;
      height: 18px;
      cursor: pointer;
      z-index: 1001;
      padding: 15px;
      background: transparent url("~images/application/crosssign.webp") no-repeat scroll center center
    }
  }

  & .embedded-quiz__description {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media #{$sm} {
      display: none;
      text-align: center;
      margin: 0;
    }

    &.never-quizzed {
      @media #{$sm} {
        display: block;
        text-align: left;
        padding: 10px;
      }
    }

    & .title {
      font-size: $tiny-font-size;
      margin-bottom: 4px;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      @media #{$sm} {
        border: 0;
        padding: 0;
      }
    }

    & .meta {
      font-size: 12px;
      color: $gray-text;
      margin: 0;
      line-height: 1.2;

      & .btn.btn-text-link {
        color: $gray-text;
        font: 400 12px var(--light);
        text-decoration: underline;
      }
    }
  }

  & .exam-passed {
    color: $gold;
    text-transform: uppercase;
    font-size: $mini-font-size;
    font-family: var(--medium);
    margin: -2px 0 6px 0;
    display: flex;
    align-items: center;
    gap: 6px;

    & .circle {
      width: 20px;
      height: 20px;
      margin: 0;
      background-color: $gold;
      @include gold-shimmer(10s);

      & .fa {
        border-radius: 50%;
        color: #B1800B;
        font-size: 11px;
      }
    }
  }
}

.embedded-quiz__results {
  padding: 30px;

  @media #{$sm} {
    padding: 20px;
  }

  .l-flex-row {
    & > * + * {
      margin-left: 13px;

      @media #{$md} {
        margin-left: 0;
      }
    }
  }

  & p.tiny {
    margin-bottom: 20px;

    &.hide-md-lg-only a {
      text-decoration: underline;
      color: $gray-text;
      white-space: nowrap;
    }
  }
}

.stats-boxes {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  @media #{$sm} {
    flex-direction: column;
  }

  .stats-box {
    width: 31.9%;
    background-color: #fff;
    border: 1px solid $gray-border;
    font-family: var(--regular);
    text-align: center;
    border-radius: 2px;
    margin-right: 13px;

    &:last-of-type {
      margin-right: 0;
    }

    @media #{$sm} {
      width: 100%;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      margin-right: 0;

      &:last-child {
        border-bottom: 1px solid $gray-border;
      }
    }

    & > .stats-box__number {
      border-bottom: 1px solid $gray-border;
      padding: 20px 0 15px;
      font-size: 40px;
      line-height: 40px;
      color: $logo-blue;

      @media #{$sm} {
        border-bottom: 0;
        border-right: 1px solid $gray-border;
        width: 70px;
        min-width: 70px;
        font-size: 20px;
        line-height: 20px;
        padding: 20px 0 17px;
      }
    }

    & > .stats-box__title {
      color: $gray-text;
      padding: 20px;
      font-size: 13px;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 76px);

      @media #{$sm} {
        text-align: left;
        padding: 10px 20px;
        height: auto;
      }

      @media #{$xs} {
        padding: 10px 10px 10px 15px;
      }
    }
  }
}

.last-training-stats {
  margin-bottom: 22px;

  & > h2 {
    color: $gray-text;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 22px;
  }
}

.last-training-stats {
  &:last-child {
    margin-bottom: 0;
  }
}

.last-training-worst-terms {
  margin-top: 15px;

  & p a {
    color: $logo-blue;
    text-decoration: none;
  }

  & > .anatomyterms {
    display: -ms-grid;
    display: grid;
    grid-gap: 13px;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-columns: 1fr 13px 1fr 13px 1fr;
    -ms-grid-rows: auto;

    & > *:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    & > *:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    & > *:nth-child(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }

    @media #{$sm} {
      display: block;
    }
  }

  & .anatomyterm {
    display: block;

    @media #{$sm} {
      border-bottom: none;
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    &:last-child {
      @media #{$sm} {
        border-bottom: 1px solid $gray-border;
      }
    }

    & .anatomyterm__image {
      @media #{$sm} {
        padding-bottom: 0;
        min-width: 70px;
      }
    }

    & > .anatomyterm__bottom {
      background-color: #fff;
      border-top: 1px solid $gray-border;
      height: 75px;

      @media #{$sm} {
        border-top: none;
        border-left: 1px solid $gray-border;
        height: 70px;
        padding: 10px 10px 10px 15px;
      }

      & > .anatomyterm-title {
        color: $logo-blue;
        font-size: 13px;
        font-family: var(--regular);
        line-height: 18px;

        @media #{$sm} {
          text-align: left;
        }
      }

    }
  }
}

.embedded-quiz .embedded-quiz__extended-info {
  margin-bottom: 0;
  padding: 30px;

  @media #{$sm} {
    padding: 20px;
  }

  & p.hide-md-lg-only {
    margin-bottom: 20px;

    & > a {
      text-decoration: underline;
      color: $gray-text;
      white-space: nowrap;
    }
  }
}

.embedded-quiz .milestone {
  margin-bottom: 0;
  padding: 30px;

  @media #{$sm} {
    padding: 20px;
  }

  & .btn {
    margin-top: 20px;
    margin-right: 0;
  }

  & img {
    margin: 0 auto;
  }
}

.demo-summary-cta {
  text-align: center;
  padding-top: 100px;
  max-width: 500px;
  margin: 0 auto;

  @media #{$xs} {
    padding-top: 30px;
  }

  & > .btn {
    margin-bottom: 50px;
  }
}

.embedded-quiz .demo-summary-cta {
  padding: 30px;
  max-width: 530px;

  @media #{$sm} {
    padding: 20px;
  }

  .headline {
    @media #{$sm} {
      font-size: 30px;
      line-height: 40px;
    }

    @media #{$xs} {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .btn {
    margin-bottom: 30px;
  }

  .demo-summary-cta__non-cta {
    font-family: var(--regular);
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;
    text-decoration: underline;
    display: block;
    color: $gray-text;
  }
}

.embedded-quiz .embedded-quiz__content {
  margin-bottom: 0;
  padding: 30px;

  @media #{$sm} {
    padding: 20px;
  }

  & > .embedded-quiz__anatomyterms {
    column-count: 2;
    margin-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    @media #{$sm} {
      column-count: 1;
    }

    & > .embedded-quiz__anatomyterm {
      margin-bottom: 8px;
      color: $gray-text;
      font-size: 13px;
      margin-right: 15px;
      line-height: 16px;

      > a:hover {
        color: $logo-blue;
      }
    }
  }
}

.embedded-quiz-info {
  padding: 20px 30px;
  border-bottom: 1px solid $gray-border;

  @media #{$sm} {
    padding: 15px;
  }

  & .container {
    background-color: $white;
    border-radius: 3px;
    padding: 15px;
  }

  & .title {
    font-size: $tiny-font-size;
    font-family: var(--regular);
    line-height: 1.2;
    border-bottom: 1px solid $gray-border;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  & .btn-link {
    height: auto;
    margin: 16px 0 4px;
    font-size: 12px;
  }

  & .learnables {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8px;

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  & .learnable-level {
    display: flex;
    align-items: center;

    & .learnable-title {
      font-size: $tiny-font-size;
    }

    & .icon {
      display: inline-block;
      color: $gray-border;
      margin-right: 6px;

      & > svg {
        height: 22px;
        width: 22px;
      }
    }
  }

  & p.hide-md-lg-only {
    margin-bottom: 20px;
  }

  & .mobile-description {
    & .mobile-title {
      font-size: $tiny-font-size;
      margin-bottom: 4px;
    }

    & .meta {
      font-size: 11px;
      font-family: var(--light);
      color: $gray-text-light;
      margin-bottom: 20px;
    }
  }
}
