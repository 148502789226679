.contentbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:not(:first-child) {
    .small {
      margin-top: 20px;
      display: inline-block;
    }
  }

  // taken from https://stackoverflow.com/a/13184714
  .anchor {
    @media #{$lg} {
      display: block;
      position: relative;
      top: -80px;
      visibility: hidden;
    }
  }

  > .hide + li > .small, // if hide before the first subheading
  ol li:first-child .small {  // subheadings on atlas/all (nested list)
    margin-top: 0;
  }

  ol, ul {
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0;
  }

  li {
    position: relative;

    &:last-child {
      clear: both;
    }
  }

  &--truncated {
    max-height: 306px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.1s linear;

    &:after {
      cursor: pointer;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 150px;
      background-image: linear-gradient(to bottom, transparent, #fff);
    }

    &:hover {
      max-height: 380px;
      transition: max-height 0.1s linear;

      &:after {
        height: 220px;
      }
    }
  }
}

// defines the height of the loading frame before e.g. the contentbox for the
// embeddded  video is loaded
.contentbox-container.spinner {
  min-height: 102px;
}

.contentbox {
  width: 100%;
  min-height: 102px;
  border: 1px solid $gray-border;
  background-color: $gray-bg-dark;
  border-radius: 2px;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: row;

  &:hover {
    border: 1px solid #b8b8b8;
  }

  & > a {
    text-decoration: none;
  }

  .contentbox-image {
    width: 101px;
    background-color: #ffffff;
    position: relative;
    border-right: 1px solid $gray-border;


    img {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: absolute;
    }

    @media #{$sm} {
      display: none;
    }

  }

  .contentbox-topic {
    background-color: $gray-bg-dark;
    padding: 0 30px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;


    & > .contentbox__new_de,
    & > .contentbox__new_en,
    & > .contentbox__new_pt,
    & > .contentbox__new_es {
      z-index: 4;
      height: 30px;
      width: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }

    & > .contentbox__new_de {
      background: transparent url("~images/content/new_indicator_de.webp") no-repeat scroll center center/30px 30px;
    }

    & > .contentbox__new_en {
      background: transparent url("~images/content/new_indicator_en.webp") no-repeat scroll center center/30px 30px;
    }

    & > .contentbox__new_pt {
      background: transparent url("~images/content/new_indicator_pt.webp") no-repeat scroll center center/30px 30px;
    }

    & > .contentbox__new_es {
      background: transparent url("~images/content/new_indicator_es.webp") no-repeat scroll center center/30px 30px;
    }

    @media #{$xs} {
      padding: 20px;
    }

    & .contentbox-title {
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 27px;
      margin-top: 0;
      font-family: var(--regular);
      color: $logo-blue;
      font-weight: normal;
      display: flex;

      & > .fa {
        font-size: 18px;
        vertical-align: baseline;
        line-height: 23px;
        margin-right: 6px;
      }
    }

    & .contentbox-description {
      font-family: var(--light);
      color: $gray-text;
      font-size: 13px;
      line-height: 22px;
      margin-top: 0;

      & .badge.badge--small {
        font-size: 9px;
        min-height: 12px;
        line-height: 14px;
        padding: 0 7px;
        margin-right: 4px;
        margin-top: -1px;
      }
    }
  }

  &--highlight {
    border: 1px solid $logo-blue-light;
    background-color: $logo-blue-bg;

    & .contentbox-image {
      border-right: 1px solid $logo-blue-lightest;
    }

    & .contentbox-topic {
      background-color: $logo-blue-bg;
    }
  }
}

.grid > .columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  clear: both;

  & > * {
    flex-basis: calc(50% - 8px);
    margin-top: 15px;
    margin-bottom: 0;

    &:nth-child(1), &:nth-child(2) {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin-left: 15px;
    }
  }

  @media #{$sm} {
    display: block;

    & > * {
      width: 100%;

      &:nth-child(1), &:nth-child(2) {
        margin-top: 15px;
      }

      &:nth-child(2n) {
        margin-left: 0;
      }
    }
  }

  & .contentbox {

    & > .contentbox-image {

      @media #{$sm} {
        display: block;
      }
    }

    & > .contentbox-topic {
      padding: 0 15px;

      & > .contentbox-title {
        line-height: 16px;
        font-size: 12px;
        margin-bottom: 7px;
      }

      & > .contentbox-description {
        font-size: 12px;
        line-height: 12px;
        font-family: var(--regular);
        text-decoration: underline;

        & > a {
          color: $gray-text;
        }
      }
    }
  }
}
