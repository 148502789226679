.video-player {
  position: relative;
  padding-bottom: 56.16%;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-controls {

  & > .video-skipper {
    display: inline;
    font-size: 27px;
    margin-right: 20px;

    & a {
      color: $gray-text-light;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        color: $logo-blue;
      }
    }
  }

  & > .video-playback-speed {
    display: inline;
    vertical-align: super;
    font-size: 14px;
    line-height: 20px;
    color: $gray-text;

    @media #{$xs} {
      display: block;
    }

    & > a {
      color: $logo-blue;
      text-decoration: none;

      &.active {
        color: $gray-text;
      }
    }
  }
}

#timestamps {
  margin-bottom: 30px;
}

.video-timestamps-headline {
  font-size: $standard-font-size;
  line-height: 21px;
  margin-bottom: 30px;
  color: $logo-blue;
  font-weight: normal;
  margin-top: 30px;
}

.video-timestamps {

  & > table {
    padding: 0;

    & > tbody {
      & > tr > td {
        font-size: $tiny-font-size;
        color: $gray-text;
        line-height: 21px;
        vertical-align: top;

        & > a {
          color: $logo-blue;
          text-decoration: none;
        }

        .chapter-link-wrapper {
          display: inline-block;
        }

        & .chapter-link {
          display: grid;
          grid-template-columns: 46px 1fr;
          column-gap: 4px;
          align-items: start;
        }

        & .chapter-title {
          color: $gray-text;
        }
      }
    }
  }
}

.videos.show {

  & section {
  margin-bottom: 40px;
  }

  & .video-timestamps {

    & > h2.listing-subheadline {
      margin-bottom: 25px;
    }
  }

  & .video-transcript {
    cursor: pointer;

    & p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 22px;
    }

    & .full {
      display: none;
    }
  }

  & .open.video-transcript {
    cursor: default;

    & .full {
      display: block;
    }

    & .short {
      display: none;
    }
  }
}
