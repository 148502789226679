.profile-setup {
  display: flex;
  min-height: 100vh;

  @media #{$md} {
    flex-direction: column;
  }
}

.setup-header {
  flex: 0 0 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: $dark-bg-blue;
  padding: 0 50px 50px;

  @media #{$md} {
    padding: 30px;
  }

  @media #{$sm} {
    padding: 15px;
  }

  p {
    color: $white;

    @media #{$md} {
      margin-bottom: 0;
    }

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }

  &__subheading {
    color: $logo-blue;
    font-size: $lead-font-size;
    font-family: var(--regular);
    margin-bottom: 5px;

    @media #{$sm} {
      font-size: $small-font-size;
    }
  }

  &__heading {
    color: $white;
    line-height: 1.2;

    @media #{$sm} {
      font-size: $lead-font-size;
      margin-bottom: 15px;
    }
  }
}

.setup-header__logo {
  @media #{$md} {
    margin-bottom: 20px
  }
}

.setup-header__footer {
  position: static;

  & > form {
    margin: 0;
  }

  @media #{$md} {
    position: absolute;
    font-size: 12px;
    top: 0;
    right: 0;
  }
}

.setup-header__skip-link {
  @media #{$md} {
    margin: 20px 20px 0 0;
    font-size: $tiny-font-size;
  }

  @media #{$sm} {
    margin: 5px 5px 0 0;
    font-size: 10px;
  }
}

#setup_survey {
  flex: 1;
  min-width: 0;
}

.setup-body {
  flex: 1;
  display: flex;
  align-items: stretch;
  background-color: $gray-bg;
  padding: 50px 80px;

  @media #{$md} {
    padding: 30px;
  }

  @media #{$sm} {
    padding: 15px;
  }

  .sd-root-modern .sd-progress {
    background-color: $white;
    margin-bottom: 0;
    border-radius: 3px;
    overflow: hidden;
    height: auto;
  }
}

.sd-root-modern {
  --font-family: var(--regular);
  --sd-base-padding: 0;
  --sd-base-vertical-padding: 0;
  background-color: inherit;

  .sd-container-modern,
  .sd-body,
  &,
  form {
    height: 100%;
    margin: 0;
  }

  .sd-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$md} {
      justify-content: unset;
    }
  }

  .sd-progress__bar {
    background-color: $green;
    transition: width .45s ease;
    height: 6px;
  }

  .sd-progress__text {
    display: none;
  }

  .sd-page__title {
    font-size: $standard-font-size;
    line-height: $standard-font-size;
    font-family: var(--regular);
    margin-bottom: 7px;
    color: $logo-blue;

    @media #{$sm} {
      font-size: $small-font-size;
    }
  }

  .sd-completedpage {
    background-color: transparent !important;
  }

  .sd-description {
    font-family: var(--regular);
    color: $gray-text-light;
    margin-bottom: 7px;
    line-height: inherit;

    @media #{$sm} {
      font-size: $tiny-font-size;
    }
  }

  .sd-description.sd-question__description {
    margin-bottom: 10px;
    font-family: var(--light-italic);
  }

  .sd-body__page {
    padding: 28px 0 10px 0;
    margin: 0;
    min-width: 0;

    @media #{$sm} {
      flex: 1;
    }

    input.sd-text,
    textarea.sd-comment,
    select.sd-dropdown,
    .sd-input {
      background-color: $white;
      font-family: var(--regular);

      &,
      &:focus {
        box-shadow: none;
      }
    }

    .sd-input::placeholder {
      color: $gray-text;
      font-family: var(--light);
    }

    textarea.sd-comment {
      border: 1px solid $gray-border;
      margin-top: 0;

      &:focus {
        border-color: $green;
      }
    }
  }

  .sd-question__header {
    display: none;
  }

  .sd-question__title {
    display: none;
  }

  .sd-selectbase {
    border: 0;
    padding: 0;
  }

  .sd-element--with-frame {
    box-shadow: inherit;
    background-color: inherit;
    padding: 0;
  }

  .sd-body--static {
    max-width: inherit;
  }

  &--mobile .sd-body.sd-body--responsive,
  &--mobile .sd-body.sd-body--static {
    padding: 0;
  }

  .sd-selectbase__label, .sd-dropdown {
    background: $white;
    border: 1px solid $gray-border;
    border-radius: 2px;
    min-height: 46px;
    padding: 10px;
    display: flex;
    margin: 0;
    align-items: center;
    transition-property: background-color, border;
    transition-duration: .45s;
    transition-timing-function: ease;

    &:focus-within {
      background: $white;
      border: 1px solid $green;
      border-radius: 2px;
    }
  }

  .sd-dropdown__filter-string-input {
    background-color: inherit;
    border: inherit;
    border-radius: inherit;
    padding: inherit;
    height: inherit;
  }

  .select2-selection--single .select2-selection__arrow {
    display: none;
  }

  .sd-radio--checked .sd-selectbase__label {
    border-color: $green;
    background-color: #ECFBF5;
  }

  .sd-item__control:focus + .sd-item__decorator,
  .sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
    box-shadow: none;
    border-color: $green;
  }

  .sd-selectbase__item {

    &:hover:not(.sd-radio--checked) .sd-selectbase__label,
    &:focus:not(.sd-radio--checked) .sd-selectbase__label {
      cursor: pointer;
      background-color: $gray-bg;
    }

    &:hover,
    &:focus {
      input[type="radio"] + span::before {
        opacity: 1;
      }
    }
  }

  .sd-item {
    padding-top: 0;
    padding-bottom: 9px;
  }

  .sd-item__control-label {
    font-family: var(--regular);
    color: $gray-text-light;
    top: 0;
  }

  .sd-item__decorator {
    position: relative;
    box-shadow: none;
  }

  .sd-question .sd-q-col-1 {
    padding-right: 0;
  }

  .sd-row {
    margin-top: 0;

    > div {
      min-width: 0 !important; // Overwrites inline plugin style
    }
  }

  .sd-footer {
    display: flex;
    justify-content: space-between;
    padding: 0;

    #sv-nav-complete,
    #sv-nav-next,
    #sv-nav-preview {
      margin-left: auto;

      .btn {
        margin-right: 0;
      }
    }

    .sv-action:not(.sv-action--hidden),
    .sd-btn {
      flex-grow: unset;
    }

    @media #{$sm} {
      margin-top: auto;
    }
  }

  .sd-body__navigation .sd-btn {
    padding: 0px 20px;
    flex-grow: 0;

    &,
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  input[type="radio"] + span {
    background: $white;
    border: 1px solid $gray-border;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin: 0 5px 0 0;
    left: 0;

    &::before {
      font-family: "fontawesome";
      content: "\e811"; // fa-check
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: $small-font-size;
      opacity: 0;
      color: $gray-border;
      transition: opacity .25s ease;
    }

    &::after {
      display: none;
    }
  }

  input[type="radio"]:checked + span {
    background: $green;
    border-color: $green;
    position: relative;

    &::before {
      opacity: 1;
      color: $white;
    }
  }

  .sd-question__erbox {
    background-color: rgba($red, .1);
    color: $red;
    font-size: $tiny-font-size;
    padding: 15px;
    margin-bottom: 10px;
  }
}

.sv-list {
  font-family: var(--regular);
  max-height: 200px;
  color: $gray-text;
}

textarea.sd-comment {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
  line-height: 20px;
  resize: none !important;
}

.sd-dropdown__filter-string-input::placeholder,
.sd-dropdown__value,
.sv-list__item-body {
  font-family: var(--light);
  color: $gray-text;
}

.sv-string-viewer {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sv-popup {
  &.sv-popup--overlay.sv-popup--overlay .sv-popup__container {
    position: absolute;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    height: 100vh;
  }

  &.sv-popup--overlay.sv-popup--overlay .sv-popup__body-content {
    position: absolute;
    bottom: 0;
  }

  .sv-list {
    max-height: 100%;
    padding: 5px;
  }

  .sv-list__filter-icon {
    display: none;
  }

  .sv-list__filter {
    padding: 0;
    border: 0;
  }

  .sv-list__input {
    border: 1px solid $gray-border;
    border-radius: 2px;

    &::placeholder {
      font-family: var(--light);
      color: $gray-text;
    }

    &:focus {
      box-shadow: none;
      border-color: $green;
    }
  }

  &.sv-popup--overlay.sv-popup--overlay .sv-popup__button {
    flex: 1 1 auto;
    background-color: $logo-blue;
    font: $medium-font-weight 14px var(--medium);
    height: 45px;
    padding: 0 20px;
    border: 0;
    border-radius: 2px;

    &:hover,
    &:focus {
      border :0;
      box-shadow: none;
    }
  }
}
